import React from "react";
import { graphql, StaticQuery } from "gatsby";
import theme from "../theme/theme.yaml";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import { FaTag, FaRss, FaPaperPlane } from "react-icons/fa";
import config from "../../content/meta/config";

const GamePage = (props) => {
  return (
    <React.Fragment>
      <Article theme={theme}>
        <header>
          <Headline title="PCWeb Tips" theme={theme} />
        </header>
        <p>ほとんど自分のメモ代わりに近いページです。</p>
        <p>
          自分で試してみて良かったTipsや、あまり一般的でないTipsを誰でも使えるように解説しています。
        </p>
        <div>
          <h2>PC Tips</h2>
          <li>Windows 10/8.1/8/7/Vistaで音質を改善する（オンボードサウンドでASIOを使う）</li>
          <li>最上級音質アニソンで毎日ハイテンション！ハイレゾ生活のための3つのコツ</li>
          <h2>Web Tips</h2>
          <li>nginxで最速Webサーバをつくる</li>
          <li>Let’s encryptでSSL&HTTP2のWebサーバをつくる</li>
          <li>Qualys SSL Reportでセキュリティ評価「A＋」なWebサーバをつくる</li>
          {/* 他のコンテンツやコンポーネントを追加 */}
        </div>
      </Article>
      <Seo pageTitle="Game" />
    </React.Fragment>
  );
};

export default GamePage;
